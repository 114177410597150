import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaSignOutAlt, FaTachometerAlt, FaChartBar, FaFileAlt, FaTools, FaUsersCog, FaHome } from 'react-icons/fa';

const Sidebar = ({ onLogout, onNavigate, userRole, dashboards }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const toggleSection = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
      onNavigate(section);
    }
  };

  const handleItemClick = (sectionTitle, item) => {
    if (sectionTitle.toLowerCase() === 'dashboards') {
      onNavigate('dashboards', item);
    } else {
      onNavigate(item.id);
    }
  };

  const sidebarSections = [
    {
      title: 'Home',
      icon: <FaHome />,
      id: 'home'
    },
    {
      title: 'Dashboards',
      icon: <FaTachometerAlt />,
      items: dashboards
    },
    {
      title: 'Spreadsheets',
      icon: <FaChartBar />,
      items: [
        { name: "Active Users", id: "activeUsers" },
        { name: "VC In Cell Usage", id: "vcInCellUsage" }
      ]
    },
    {
      title: 'Tools',
      icon: <FaTools />,
      items: [
        { name: "Document Chat", id: "documentQA" },
        { name: "Bid Builder", id: "bidBuilder" },
        { name: "Document Translator", id: "documentTranslator" }
      ]
    }
  ];

  if (userRole === 'Admin') {
    sidebarSections.push({
      title: 'Admin Portal',
      icon: <FaUsersCog />,
      items: [
        { name: "User Management", id: "userManagement" },
        { name: "Dashboard Management", id: "dashboardManagement" }
      ]
    });
  }

  return (
    <aside className={`fixed left-0 top-0 bottom-0 w-64 transition-all duration-300 ease-in-out overflow-y-auto bg-[#2F2235] text-white ${isOpen ? '' : '-translate-x-48'}`}>
      <button 
        className="absolute right-2 top-2 text-white p-1 rounded-full bg-[#3F3244] hover:bg-[#4F4254]"
        onClick={toggleSidebar}
      >
        {isOpen ? '<<' : '>>'}
      </button>
      <nav className="mt-10">
        <ul className="space-y-2 px-3">
          {sidebarSections.map((section) => (
            <li key={section.title}>
              {section.id === 'home' ? (
                <button
                  className="flex items-center w-full p-2 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-[#3F3244]"
                  onClick={() => onNavigate('home')}
                >
                  <span className="flex-shrink-0 w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white">
                    {section.icon}
                  </span>
                  <span className="flex-1 ml-3 text-left whitespace-nowrap">{section.title}</span>
                </button>
              ) : (
                <>
                  <button
                    className="flex items-center w-full p-2 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-[#3F3244]"
                    onClick={() => toggleSection(section.title.toLowerCase())}
                  >
                    <span className="flex-shrink-0 w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white">
                      {section.icon}
                    </span>
                    <span className="flex-1 ml-3 text-left whitespace-nowrap">{section.title}</span>
                    {expandedSection === section.title.toLowerCase() ? <FaChevronUp /> : <FaChevronDown />}
                  </button>
                  {expandedSection === section.title.toLowerCase() && section.items && (
                    <ul className="py-2 space-y-2">
                      {section.items.map((item) => (
                        <li key={item.id}>
                          <a
                            href="#"
                            onClick={(e) => { e.preventDefault(); handleItemClick(section.title, item); }}
                            className="flex items-center w-full p-2 text-base font-normal text-white transition duration-75 rounded-lg pl-11 group hover:bg-[#3F3244]"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      </nav>
      <button 
        className="flex items-center w-full p-2 mt-6 text-base font-normal text-white rounded-lg transition duration-75 group hover:bg-[#3F3244]"
        onClick={onLogout}
      >
        <FaSignOutAlt className="flex-shrink-0 w-6 h-6 text-gray-400 transition duration-75 group-hover:text-white" />
        <span className="ml-3">Logout</span>
      </button>
    </aside>
  );
};

export default Sidebar;
