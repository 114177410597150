import React, { useState, useEffect } from 'react';
import styles from './DashboardManagement.module.css';

const DashboardManagement = ({ handleDashboardUpdate }) => {
  const [dashboards, setDashboards] = useState([]);
  const [newDashboard, setNewDashboard] = useState({ name: '', reportId: '', workspaceId: '', visible: true });
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    fetchDashboards();
  }, []);

  const fetchDashboards = async () => {
    try {
      const response = await fetch('https://dml-portal-functions.azurewebsites.net/api/dashboards?visibleOnly=false', {
        headers: {
          'X-User-Role': 'Admin'
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setDashboards(data);
    } catch (error) {
      console.error("Error fetching dashboards:", error);
    }
  };

  const handleCreateDashboard = () => {
    const newDashboardWithId = { 
      ...newDashboard, 
      id: newDashboard.name.replace(/\s+/g, ''),  // Use the name (without spaces) as the ID
      isNew: true 
    };
    setDashboards([...dashboards, newDashboardWithId]);
    setChanges([...changes, { type: 'create', dashboard: newDashboardWithId }]);
    setNewDashboard({ name: '', reportId: '', workspaceId: '', visible: true });
  };

  const handleDeleteDashboard = (id) => {
    setDashboards(dashboards.filter(dashboard => dashboard.id !== id));
    setChanges([...changes, { type: 'delete', id }]);
  };

  const handleToggleVisibility = (id, currentVisibility) => {
    setDashboards(dashboards.map(dashboard => 
      dashboard.id === id ? { ...dashboard, visible: !currentVisibility } : dashboard
    ));
    setChanges([...changes, { type: 'update', id, updates: { visible: !currentVisibility } }]);
  };

  const saveChanges = async () => {
    for (const change of changes) {
      try {
        if (change.type === 'create') {
          const response = await fetch('https://dml-portal-functions.azurewebsites.net/api/dashboards', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-User-Role': 'Admin'
            },
            body: JSON.stringify(change.dashboard)
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (!data.success) {
            throw new Error(data.error || 'Unknown error occurred');
          }
        } else if (change.type === 'delete') {
          const response = await fetch(`https://dml-portal-functions.azurewebsites.net/api/dashboards/${change.id}`, {
            method: 'DELETE',
            headers: {
              'X-User-Role': 'Admin'
            }
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (!data.success) {
            throw new Error(data.error || 'Unknown error occurred');
          }
        } else if (change.type === 'update') {
          const response = await fetch(`https://dml-portal-functions.azurewebsites.net/api/dashboards/${change.id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'X-User-Role': 'Admin'
            },
            body: JSON.stringify(change.updates)
          });
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          if (!data.success) {
            throw new Error(data.error || 'Unknown error occurred');
          }
          setDashboards(dashboards.map(dashboard => 
            dashboard.id === change.id ? { ...dashboard, ...change.updates } : dashboard
          ));
        }
      } catch (error) {
        console.error('Error applying change:', error);
        alert(`Failed to apply change: ${error.message}`);
      }
    }
    setChanges([]);
    fetchDashboards();
    handleDashboardUpdate();
  };

  const discardChanges = () => {
    setChanges([]);
    fetchDashboards();
  };

  return (
    <div className={styles.dashboardManagement}>
      <h2 className={styles.heading}>Dashboard Management</h2>
      <form onSubmit={(e) => { e.preventDefault(); handleCreateDashboard(); }} className={styles.form}>
        <input
          type="text"
          placeholder="Dashboard Name"
          value={newDashboard.name}
          onChange={(e) => setNewDashboard({ ...newDashboard, name: e.target.value })}
          className={styles.input}
        />
        <input
          type="text"
          placeholder="Report ID"
          value={newDashboard.reportId}
          onChange={(e) => setNewDashboard({ ...newDashboard, reportId: e.target.value })}
          className={styles.input}
        />
        <input
          type="text"
          placeholder="Workspace ID"
          value={newDashboard.workspaceId}
          onChange={(e) => setNewDashboard({ ...newDashboard, workspaceId: e.target.value })}
          className={styles.input}
        />
        <button type="submit" className={styles.button}>Add Dashboard</button>
      </form>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Report ID</th>
            <th>Workspace ID</th>
            <th>Visible</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {dashboards.map((dashboard) => (
            <tr key={dashboard.id} className={dashboard.isNew ? styles.newRow : ''}>
              <td>{dashboard.name}</td>
              <td>{dashboard.reportId}</td>
              <td>{dashboard.workspaceId}</td>
              <td>
                <input
                  type="checkbox"
                  checked={dashboard.visible}
                  onChange={() => handleToggleVisibility(dashboard.id, dashboard.visible)}
                />
              </td>
              <td>
                <button onClick={() => handleDeleteDashboard(dashboard.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {changes.length > 0 && (
        <div className={styles.changeActions}>
          <button onClick={saveChanges} className={styles.button}>Save Changes</button>
          <button onClick={discardChanges} className={styles.button}>Discard Changes</button>
        </div>
      )}
    </div>
  );
};

export default DashboardManagement;