import React from 'react';
import { FaTachometerAlt, FaChartBar, FaTools } from 'react-icons/fa';

const Dashboard = ({ onReportSelect }) => {
  console.log('Dashboard: Rendering main dashboard component');

  const handleSectionClick = (sectionId) => {
    console.log('Dashboard: Section clicked:', sectionId);
    onReportSelect(sectionId);
  };

  const sections = [
    {
      title: 'Dashboards',
      description: 'Browse Power BI dashboards.',
      icon: <FaTachometerAlt className="w-12 h-12 text-blue-500" />,
      id: 'dashboards'
    },
    {
      title: 'Spreadsheets',
      description: 'Export your data.',
      icon: <FaChartBar className="w-12 h-12 text-green-500" />,
      id: 'spreadsheets'
    },
    {
      title: 'Tools',
      description: 'Access various tools to assist with your work.',
      icon: <FaTools className="w-12 h-12 text-purple-500" />,
      id: 'tools'
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mb-4">
        Welcome to the Data & Machine Learning Portal
      </h1>
      <p className="mt-2 text-lg text-gray-600 mb-8">
        Here you can find Power BI reports, exportable spreadsheets, and tools to help you with your work. 
        Please contact a member of the Data & Machine Learning team if you have any questions, or require access to a specific area of the portal.
      </p>
      
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {sections.map((section) => (
          <div
            key={section.id}
            className="bg-white overflow-hidden shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out cursor-pointer"
            onClick={() => handleSectionClick(section.id)}
          >
            <div className="p-6">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {section.icon}
                </div>
                <div className="ml-4">
                  <h2 className="text-xl font-semibold text-gray-900">{section.title}</h2>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-base text-gray-500">
                  {section.description}
                </p>
              </div>
            </div>
            <div className="bg-gray-50 px-6 py-4">
              <div className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                Explore {section.title} →
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
