import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ChatMessage from './ChatMessage';
import { FaRobot, FaMicrophone, FaPaperPlane } from 'react-icons/fa';

function ChatInterface({ namespace, isIndexing }) {
  const [chatHistory, setChatHistory] = useState([]);
  const chatHistoryRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [userInput, setUserInput] = useState('');
  const [loading, setLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistory]);

  const toggleRecording = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      audioChunksRef.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        await sendAudioToBackend(audioBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const sendAudioToBackend = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');

      const response = await axios.post('https://dml-portal-functions.azurewebsites.net/api/transcribe', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const transcription = response.data.transcript;
      
      if (transcription) {
        handleChat(transcription);
      }
    } catch (error) {
      console.error('Error sending audio to backend:', error);
    }
  };

  const handleChat = async (message) => {
    if (!message || loading || !namespace) return;

    setLoading(true);
    setChatHistory(prevHistory => [...prevHistory, { role: 'user', content: message }]);
    setUserInput('');

    try {
      const response = await axios.post('https://dml-portal-functions.azurewebsites.net/api/chat', {
        query: message,
        namespace: namespace
      });
      setChatHistory(prevHistory => [...prevHistory, 
        { 
          role: 'assistant', 
          content: response.data.message, 
          sources: response.data.sources 
        }
      ]);
    } catch (error) {
      console.error('Error in chat:', error);
      setChatHistory(prevHistory => [...prevHistory, 
        { role: 'assistant', content: 'An error occurred while processing your request.', error: true }
      ]);
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="flex-1 overflow-y-auto p-4 space-y-4" ref={chatHistoryRef}>
        {chatHistory.map((msg, index) => (
          <ChatMessage key={index} message={msg} />
        ))}
        {loading && (
          <div className="flex items-center space-x-2 p-3 bg-gray-100 rounded-lg">
            <FaRobot className="text-[#60495A] text-xl" />
            <div className="flex space-x-1">
              <div className="w-2 h-2 bg-[#60495A] rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
              <div className="w-2 h-2 bg-[#60495A] rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
              <div className="w-2 h-2 bg-[#60495A] rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
            </div>
          </div>
        )}
      </div>
      
      <div className="p-4 bg-gray-100 border-t border-gray-200">
        <div className="flex items-center space-x-2">
          <input 
            type="text"
            value={userInput} 
            onChange={(e) => setUserInput(e.target.value)} 
            placeholder={isIndexing ? "Indexing in progress..." : "Ask a question..."} 
            className="flex-1 py-2 px-4 bg-white border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#60495A] focus:border-transparent"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !loading && !isIndexing) {
                handleChat(userInput);
              }
            }}
            disabled={isIndexing}
          />
          <button 
            onClick={() => handleChat(userInput)} 
            disabled={loading || isRecording || isIndexing} 
            className="p-2 bg-[#60495A] text-white rounded-full hover:bg-[#7A5C72] focus:outline-none focus:ring-2 focus:ring-[#60495A] focus:ring-offset-2 transition duration-150 ease-in-out disabled:opacity-50"
          >
            <FaPaperPlane className="w-5 h-5" />
          </button>
          <button
            onClick={toggleRecording}
            disabled={loading}
            className={`p-2 ${isRecording ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'} text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ${isRecording ? 'focus:ring-red-500' : 'focus:ring-green-500'} transition duration-150 ease-in-out`}
          >
            <FaMicrophone className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChatInterface;
