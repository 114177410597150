import React, { useState, useEffect, useCallback } from 'react';
import Login from './components/auth/Login';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import UserManagement from './components/UserManagement';
import DashboardManagement from './components/DashboardManagement';
import Layout from './components/Layout';
import SummaryPage from './components/SummaryPage';
import ReportContainer from './components/ReportContainer';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userReports, setUserReports] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [selectedPage, setSelectedPage] = useState(null);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [dashboards, setDashboards] = useState([]);

  const fetchDashboards = useCallback(async () => {
    try {
      const response = await fetch('https://dml-portal-functions.azurewebsites.net/api/dashboards?visibleOnly=true', {
        method: 'GET',
        headers: {
          'X-User-Role': userRole
        }
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setDashboards(data);
    } catch (error) {
      console.error("Error fetching dashboards:", error);
    }
  }, [userRole]);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setIsLoggedIn(true);
      setUserReports(storedUser.reports);
      setUserRole(storedUser.role);
      fetchDashboards();
    }
  }, [fetchDashboards]);

  const handleLoginSuccess = (reports, role) => {
    setIsLoggedIn(true);
    setUserReports(reports);
    setUserRole(role);
    fetchDashboards();
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserReports([]);
    setUserRole('');
    setSelectedPage(null);
    setDashboards([]);
    localStorage.removeItem('user');
  };

  const handleNavigate = (page, item) => {
    console.log('App: handleNavigate called with:', page, item);
    console.log('APP.JS: ITEM:', item);
    if (page === 'dashboards' && item) {
      
      console.log('App: Setting selectedReportId:', item.id);
      console.log('App: Setting selectedReportName:', item.name);
      setSelectedReportId(item.id);
      setSelectedPage('report');
    } else {
      console.log('App: Setting selectedPage to:', item?.id || page);
      setSelectedPage(item?.id || page);
      setSelectedReportId(null);
    }
  };
  

  const handleDashboardUpdate = () => {
    fetchDashboards();
  };

  const renderContent = () => {
    console.log('App: Rendering content for selectedPage:', selectedPage);
    switch (selectedPage) {
      case 'userManagement':
        return userRole === 'Admin' ? <UserManagement /> : <div>Access Denied</div>;
      case 'dashboardManagement':
        return userRole === 'Admin' ? <DashboardManagement handleDashboardUpdate={handleDashboardUpdate} /> : <div>Access Denied</div>;
      case 'documentQA':
        return <Layout />;
      case 'bidBuilder':
      case 'documentTranslator':
        return <div>This feature is coming soon!</div>;
      case 'report':
        return <ReportContainer reportName={selectedReportId} userReports={userReports} />;
      case 'dashboards':
        console.log('App: Rendering Dashboards SummaryPage');
        return <SummaryPage 
          title="Dashboards" 
          description="Browse Power BI dashboards. If you need access to a dashboard, please contact the Data & Machine Learning Team."
          items={dashboards}
          onNavigate={handleNavigate}
        />;
      case 'spreadsheets':
        return <SummaryPage 
          title="Spreadsheets" 
          description="Access various spreadsheets for data analysis and reporting."
          items={[
            { name: "Active Users", id: "activeUsers" },
            { name: "VC In Cell Usage", id: "vcInCellUsage" }
          ]}
          onNavigate={handleNavigate}
        />;
      case 'tools':
        return <SummaryPage 
          title="Tools" 
          description="Access various tools to assist with your work."
          items={[
            { name: "Document Chat", id: "documentQA" },
            { name: "Bid Builder", id: "bidBuilder" },
            { name: "Document Translator", id: "documentTranslator" }
          ]}
          onNavigate={handleNavigate}
        />;
      case 'admin portal':
        return userRole === 'Admin' ? <SummaryPage 
          title="Admin Portal" 
          description="Manage users and dashboards."
          items={[
            { name: "User Management", id: "userManagement" },
            { name: "Dashboard Management", id: "dashboardManagement" }
          ]}
          onNavigate={handleNavigate}
        /> : <div>Access Denied</div>;
      default:
        return <Dashboard
          selectedReport={selectedPage} 
          userReports={userReports} 
          dashboards={dashboards}
          onReportSelect={handleNavigate}
        />;
    }
  };

  return (
    <div className="App">
      {isLoggedIn ? (
        <div className="app-container">
          <Sidebar 
            onLogout={handleLogout} 
            onNavigate={handleNavigate} 
            userRole={userRole} 
            dashboards={dashboards}
          />
          <main className="main-content">
            {renderContent()}
          </main>
        </div>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;
