import React from 'react';
import { FaTachometerAlt, FaChartBar, FaTools, FaUsersCog, FaFileAlt, FaComments, FaHammer, FaLanguage } from 'react-icons/fa';
import { TbDeviceIpadHorizontalCog } from "react-icons/tb";

const SummaryPage = ({ title, description, items, onNavigate }) => {
  const getIcon = (title, itemName, itemId) => {
    switch (title.toLowerCase()) {
      case 'dashboards':
        return <FaTachometerAlt className={`w-12 h-12 text-blue-500`} />;
      case 'spreadsheets':
        return <FaChartBar className="w-12 h-12 text-green-500" />;
      case 'tools':
        switch (itemName.toLowerCase()) {
          case 'document chat':
            return <FaComments className="w-12 h-12 text-blue-500" />;
          case 'bid builder':
            return <FaHammer className="w-12 h-12 text-yellow-500" />;
          case 'document translator':
            return <FaLanguage className="w-12 h-12 text-purple-500" />;
          default:
            return <FaTools className="w-12 h-12 text-gray-500" />;
        }
      case 'admin portal':
        switch (itemName.toLowerCase()) {
            case 'user management':
                return <FaUsersCog className="w-12 h-12 text-red-500" />;
            case 'dashboard management':
              return <TbDeviceIpadHorizontalCog className="w-12 h-12 text-pink-500" />;
            default:
                return <FaFileAlt className="w-12 h-12 text-gray-500" />;
        }
        

    }
  };

  const handleItemClick = (item) => {
    console.log('SummaryPage: Item clicked:', item);
    onNavigate(title.toLowerCase(), item);  // Pass the entire item object
  };
  

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mb-4">{title}</h1>
      <p className="mt-2 text-lg text-gray-600 mb-8">{description}</p>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {items.map((item) => (
          <div
            key={item.id}
            className="bg-white overflow-hidden shadow-lg rounded-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out cursor-pointer"
            onClick={() => handleItemClick(item)}
          >
            <div className="p-6">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  {getIcon(title, item.name, item.id)}
                </div>
                <div className="ml-4">
                  <h2 className="text-xl font-semibold text-gray-900">{item.name}</h2>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-base text-gray-500">
                  Click to access {item.name}
                </p>
              </div>
            </div>
            <div className="bg-gray-50 px-6 py-4">
              <div className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                Open {item.name} →
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SummaryPage;
