import React from 'react';
import { FaUser, FaRobot } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';

function ChatMessage({ message }) {
    const isUser = message.role === 'user';
    const Icon = isUser ? FaUser : FaRobot;
  
    return (
      <div className={`flex items-start gap-2.5 mb-4 ${isUser ? 'justify-end' : 'justify-start'}`}>
        {!isUser && (
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center">
            <FaRobot className="text-gray-600 text-lg" />
          </div>
        )}
        <div className={`flex flex-col max-w-[80%] leading-1.5 p-4 border-gray-200 ${isUser ? 'bg-[#60495A] text-white rounded-s-xl rounded-ee-xl' : 'bg-gray-100 text-gray-900 rounded-e-xl rounded-es-xl'}`}>
          <div className="flex items-center space-x-2 rtl:space-x-reverse">
            <span className="text-base font-semibold">
              {isUser ? 'You' : 'AI Assistant'}
            </span>
          </div>
          <div className="text-base font-normal py-2.5 whitespace-pre-wrap break-words markdown-content">
            {isUser ? (
              <p>{message.content}</p>
            ) : (
              <ReactMarkdown>{message.content}</ReactMarkdown>
            )}
          </div>
          {!isUser && message.sources && (
            <div className="mt-2 text-sm text-gray-600">
              <p className="font-semibold">Sources:</p>
              <ul className="list-disc pl-4">
                {message.sources.map((source, index) => (
                  <li key={index}>{source}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {isUser && (
          <div className="flex-shrink-0 w-10 h-10 rounded-full bg-[#60495A] flex items-center justify-center">
            <FaUser className="text-white text-lg" />
          </div>
        )}
      </div>
    );
}

export default ChatMessage;
